import Link from 'next/link'
import style from './common.module.css'
import { inheritFont } from '@styles/_class-names'
import type { HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from 'react'
import { motion } from 'framer-motion'

export type Props = {
  children: ReactNode
  href?: string
  onClick?: MouseEventHandler<HTMLButtonElement> | 'submit'
  target?: HTMLAttributeAnchorTarget

  className?: string
}

/**
 * @description
 * If onClick is defined, render a button.
 * If the button is a submit button, onClick should be explicit defined as 'submit'. <br>
 * If onClick is undefined, but href is defined, render a link. <br>
 * If onClick and href are both undefined, render a div. <br>
 * The difference between this and the LinkWrapper is this is a block element,
 * while LinkWrapper is an inline element.
 *
 * Default width is fit-content.
 */
export function ButtonWrapper({
  children,
  href,
  onClick,
  target = '_self',
  className = style.fitContent,
}: Props): JSX.Element {
  const commonClassName = [inheritFont, className].join(' ')

  const animation = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  // Button wrapper
  if (onClick !== undefined) {
    return (
      <motion.button
        initial="hidden"
        animate="visible"
        variants={animation}
        className={[style.button, commonClassName].join(' ')}
        onClick={onClick === 'submit' ? undefined : onClick}
      >
        {children}
      </motion.button>
    )
  }

  // Link wrapper
  if (href !== undefined) {
    return (
      <Link href={href}>
        <a
          className={[style.a, commonClassName].join(' ')}
          rel={target === '_self' ? undefined : 'noreferrer'}
          target={target}
        >
          {children}
        </a>
      </Link>
    )
  }

  // Badge wrapper
  return <div className={[style.div, commonClassName].join(' ')}>{children}</div>
}
