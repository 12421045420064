import commonStyle from './common.module.css'
import primaryStyle from './primary.module.css'
import secondaryStyle from './secondary.module.css'
import successStyle from './success.module.css'
import dottedBorderStyle from './dotted-border.module.css'
import whiteStyles from './white.module.css'
import blackStyles from './black.module.css'

export default {
  ...commonStyle,
  ...primaryStyle,
  ...secondaryStyle,
  ...successStyle,
  ...dottedBorderStyle,
  ...whiteStyles,
  ...blackStyles,
}
