// @ts-nocheck
import style from './common.module.css'
import type { IconProps } from '../../../_types'
import type { CSSProperties, ElementType, ReactNode } from 'react'

export type Variant = 'normal' | 'placeholder'

export type Props = {
  children: ReactNode
  Icon?: ElementType<IconProps>

  variant?: Variant
  className?: string
  style?: CSSProperties
}

export function Label({
  children,
  Icon,

  variant = 'normal',
  className,
  style: inlineStyle,
}: Props): JSX.Element {
  return (
    <div
      className={[style.wrapper, style[variant], className, Icon ? style.withIcon : ''].join(' ')}
      style={inlineStyle}
    >
      {Icon && <Icon />}
      {children}
    </div>
  )
}
