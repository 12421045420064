import Link from 'next/link'
import style from './_styles'
import { Label } from './label'
import { Loader } from './loader'
import type { Props } from './_types'
import { getButtonCssVariables } from '@library/_buttons/button/_utils'
import { parseBoolean } from '@utils/connascence'

/**
 * @description
 * If onClick is defined, render a button.
 * If the button is a submit button, onClick should be explicit defined as 'submit'. <br>
 * If the button is loading, than it is disabled as well. <br>
 * If onClick is undefined, but href is defined, render a link. <br>
 * If onClick and href are both undefined, render a div. <br>
 */
export function Button({
  onClick,
  href,
  target = '_self',
  disabled = false,
  isLoading = false,
  children,
  Icon,
  id,
  isFullWidth = false,
  variant = 'primary',
  className,
  channelTheme,
}: Props): JSX.Element {
  const commonClassName = [
    isFullWidth ? style.fullWidth : style.contentWidth,
    style[variant],
    className,
  ].join(' ')

  const cssVariables = getButtonCssVariables(variant, channelTheme)
  // Button
  if (onClick !== undefined) {
    return (
      <button
        onClick={onClick === 'submit' ? undefined : onClick}
        disabled={disabled || isLoading}
        id={id}
        className={[
          style.button,
          isLoading ? style[variant + 'IsLoading'] : '',
          commonClassName,
        ].join(' ')}
        style={cssVariables}
      >
        {isLoading ? (
          <>
            <Loader channelTheme={channelTheme} />
            {/* The following element can keep the button width */}
            <Label Icon={Icon} variant="placeholder">
              {children}
            </Label>
          </>
        ) : (
          <Label Icon={Icon}>{children}</Label>
        )}
      </button>
    )
  }

  // TODO: add loading state into the anchor, other need the following hack
  // <Button onClick={() => undefined} isLoading={true} className={className}>
  //     Subscribe
  // </Button>
  // Link
  if (href !== undefined) {
    return (
      <Link href={href}>
        <a
          className={[style.a, commonClassName].join(' ')}
          id={id}
          rel={target === '_self' ? undefined : 'noreferrer'}
          target={target}
          style={cssVariables}
        >
          <Label Icon={Icon}>{children}</Label>
        </a>
      </Link>
    )
  }

  // TODO: add loading state into the content-badge
  // Badge
  return (
    <div id={id} className={[style.div, commonClassName].join(' ')} style={cssVariables}>
      <Label Icon={Icon}>{children}</Label>
    </div>
  )
}
