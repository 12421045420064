import loaderStyles from './styles.module.scss'
import type { Theme, Variant } from '@features/_ui/cta/_types'

export interface Props {
  variant?: Variant
  theme?: Theme
}

export default function Loader({
  theme = 'braveryTrust',
  variant = 'primary',
}: Props): JSX.Element {
  const loaderDotClasses = [
    loaderStyles.dot,
    variant === 'hyperlink' ? loaderStyles[theme] : loaderStyles[variant],
  ].join(' ')

  return (
    <div className={loaderStyles.container}>
      <div className={loaderDotClasses} />
      <div className={loaderDotClasses} />
      <div className={loaderDotClasses} />
    </div>
  )
}
