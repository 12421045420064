import { useEffect, useRef, useState } from 'react'

export default function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef<HTMLDivElement>(null)
  const refTrigger = useRef<HTMLButtonElement>(null)

  /**
   * Handle click outside
   * If the user has clicked outside, we're going to close the component.
   * If the user has clicked the element than open the component ( refTrigger ),
   * we're going to close the element by handleToggleComponent
   * @param event
   */
  const handleClickOutside = (event: Event) => {
    if (refTrigger.current !== event.target) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsComponentVisible(false)
      }
    }
  }

  // Bonus! when the user press Escape, we close the component
  const handleHideComponent = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false)
    }
  }

  // Toggle component
  const handleToggleComponent = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  // Make component visible
  const forceVisibleComponent = () => {
    setIsComponentVisible(true)
  }

  // Hide component
  const forceNoVisibleComponent = () => {
    setIsComponentVisible(false)
  }

  // On scroll set visible false
  const handleOnWheel = () => {
    setIsComponentVisible(false)
  }

  useEffect(() => {
    if (isComponentVisible) {
      document.addEventListener('keydown', handleHideComponent, true)
      document.addEventListener('click', handleClickOutside, true)
      document.addEventListener('wheel', handleOnWheel, true)
    } else {
      // If the component is not visible, we don't need to append this actions on the dom
      document.removeEventListener('keydown', handleHideComponent, true)
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('wheel', handleOnWheel, true)
    }

    return () => {
      document.removeEventListener('keydown', handleHideComponent, true)
      document.removeEventListener('click', handleClickOutside, true)
      document.removeEventListener('wheel', handleOnWheel, true)
    }
  }, [isComponentVisible])

  const zIndex = isComponentVisible ? '11' : '3'

  return {
    ref,
    refTrigger,
    isComponentVisible,
    setIsComponentVisible,
    handleToggleComponent,
    forceVisibleComponent,
    zIndex,
    forceNoVisibleComponent,
  }
}
