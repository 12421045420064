import { FADE_IN } from '@shared/constants/root.constants'
import { motion } from 'framer-motion'
import React, { ReactElement } from 'react'
import ctaStyles from '../styles.module.scss'
import useComponentVisible from '@utils/use-component-visible'
import type { IconPosition, Theme, TypeButton, Variant } from '../_types'
import type { MouseEventHandler } from 'react'
import RenderIf from '@root/utils/render-if'
import Loader from '@features/_ui/loader'
import { ChannelThemeInterface } from '@shared/interfaces/creators/channel-theme.interface'

export interface Props {
  disabled?: boolean
  label: string
  labelMobile?: string
  loading?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  theme?: Theme
  type?: TypeButton
  variant: Variant
  icon?: React.ReactNode
  iconPosition?: IconPosition
  id?: string
  // Prop to add a hover message to a button
  onHover?: string
  hideIconMobile?: boolean
  className?: string
  channelTheme?: ChannelThemeInterface
}

export default function Button({
  disabled = false,
  icon,
  iconPosition = 'left',
  label,
  labelMobile = label,
  loading = false,
  onClick,
  theme = 'black',
  type = 'button',
  variant,
  onHover,
  hideIconMobile = false,
  className,
  id = `${label}-button`,
  channelTheme,
}: Props): ReactElement {
  // Styles and variants
  const buttonClassesName = [ctaStyles.btn, ctaStyles[variant], className]
  const hasIcon = typeof icon !== 'undefined'

  // Loading state
  if (loading) {
    buttonClassesName.push(ctaStyles.loading)
  }

  // Disabled
  if (disabled) {
    buttonClassesName.push(ctaStyles.disabled)
  }

  if (hasIcon) {
    buttonClassesName.push(ctaStyles.icon)
    buttonClassesName.push(iconPosition === 'left' ? ctaStyles.left : ctaStyles.right)
  }

  // Hyperlink variant
  if (variant === 'hyperlink') {
    buttonClassesName.push(ctaStyles[theme])
  }

  if (hideIconMobile) {
    buttonClassesName.push(ctaStyles.hideIconMobile)
  }

  // Hyperlink content/ Reusing styles
  const hyperlinkContentClasses = [
    ctaStyles.hyperlinkContent,
    iconPosition === 'left' ? ctaStyles.left : ctaStyles.right,
  ].join(' ')

  const buttonLabels = (
    <>
      <span className={ctaStyles.desktopText}>{label}</span>
      <span className={ctaStyles.mobileText}>{labelMobile?.length > 0 ? labelMobile : label}</span>
    </>
  )

  const buttonContent = hasIcon ? (
    <p className={hyperlinkContentClasses}>
      {icon} {buttonLabels}
    </p>
  ) : (
    buttonLabels
  )
  const buttonContentRender = loading ? <Loader variant={variant} theme={theme} /> : buttonContent

  const { isComponentVisible, ref, setIsComponentVisible } = useComponentVisible(false)
  const handleMouseOnEnter = () => {
    if (onHover) {
      setIsComponentVisible(true)
    }
  }
  const handleMouseLeave = () => {
    if (onHover) {
      setIsComponentVisible(false)
    }
  }

  const cssVariables: Record<string, unknown> = {
    '--btnColour': channelTheme?.buttonColour ?? 'var(--vidzingEarth)',
  }

  return (
    <>
      <button
        className={buttonClassesName.join(' ')}
        onClick={onClick}
        type={type}
        // Disabled handles un clickable
        disabled={disabled}
        // Allow on hover to show some message
        onMouseEnter={handleMouseOnEnter}
        onMouseLeave={handleMouseLeave}
        id={id}
        style={cssVariables}
      >
        {buttonContentRender}
      </button>
      <RenderIf isTrue={onHover !== undefined && isComponentVisible}>
        <motion.div
          ref={ref}
          className={ctaStyles.hoverMessage}
          initial="hidden"
          animate="visible"
          variants={FADE_IN}
        >
          <span>{onHover}</span>
        </motion.div>
      </RenderIf>
    </>
  )
}
